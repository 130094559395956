<template>
 <!-- <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-order-chart :data="data.statisticsOrder" />
          </b-col>
          
          <b-col
            lg="6"
            md="3"
            cols="6"
          >
            <ecommerce-profit-chart :data="data.statisticsProfit" />
          </b-col>
          <b-col
            lg="12"
            md="6"
          >
            <ecommerce-earnings-chart :data="data.earningsChart" />
          </b-col>
        </b-row>
      </b-col>

      
      <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col>
      
    </b-row>

    <b-row class="match-height">
      
      <b-col lg="8">
        <ecommerce-company-table :table-data="data.companyTable" />
      </b-col>
      
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-meetup :data="data.meetup" />
      </b-col>
      
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-browser-states />
      </b-col>
      

      
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />
      </b-col>
      

      
      <b-col
        lg="4"
        md="6"
      >
        <ecommerce-transactions :data="data.transactionData" />
      </b-col>
      
    </b-row>
  </section> -->
  <section id="dashboard-ecommerce">
    <b-col cols="14">
      <!-- Ventas -->
      <chartjs-line-chart />
    </b-col>
    <!--  -->
    <b-col cols="14">
      <ChartjsLineChartTransactions/>
    </b-col>
    

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'


import ChartjsLineChart from '../../charts-and-maps/charts/chartjs/ChartjsLineChart.vue'
import ChartjsLineChartTransactions from '../../charts-and-maps/charts/chartjs/ChartjsLineChartTransactions.vue'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    ChartjsLineChart,
    ChartjsLineChartTransactions
  },
  data() {
    return {
      data: {},
    }
  },
  mounted(){
    this.$store.commit("appConfig/changeLoading", false);
  },
  created() {
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
    this.$store.commit("appConfig/changeLoading", true);
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
