<template>
  <b-card no-body style="padding-bottom: 20px;">
    <b-card-header>
      <div class="container">
        <div class="row align-items-center">
          <div class="col">
            <b-card-title class="mb-1 mt-1"> Transacciones </b-card-title>
          </div>
          <div class="mb-1">
        <b-dropdown
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :text="yearSelected"
          variant="info"
          class="btn-info"
          id="anioChart"
          right
        >
          <b-dropdown-item v-for="(item, index) in currentYear" :key="index" @click="selectYear(item)">
            {{item}}
              
          </b-dropdown-item>
                   
        </b-dropdown>
      </div>
        </div>
      </div>
    </b-card-header>

    <!-- chart -->
     

    <b-card-body v-if="dataChart != null && !reloadChart && !loadedLabelNoData">
      <!-- <div id="loading-bg" v-if="loading">
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div> -->
      <div v-if="loadedGraph && !loadedLabelNoData">
        <chartjs-component-line-chart
          v-if="!$store.state.appConfig.loading"
          :height="400"
          :data="dataChart"
          :options="chartjsData.lineChart.options"
          :plugins="plugins"
        />
      </div>
    </b-card-body>
    <!-- chart -->

    <!-- Values -->

    <div v-if="loadedGraph && !loadedLabelNoData" class="row align-items-center justify-content-end mr-3">
      <div class="col">
        <div class="contenedor">
          <label class="format-values-label text-left"
            >Total <br/> {{ this.currency }} {{ totalValue.toFixed(2) }}</label
          >

            <label
            class="
              format-values-label format-pasarelas-values format-stripe-label
              text-left
            "
            ><!-- <span class="font-size-pasarelas-icon">●</span>  -->Stripe <br />{{ currency }} {{ stripeValue.toFixed(2) }}</label
          >

          <label
            class="
              format-values-label format-pasarelas-values format-kushki-label
              text-left
            "
            ><!-- <span class="font-size-pasarelas-icon">●</span>  -->Kushki <br />{{ currency }} {{ kushkiValue.toFixed(2) }}</label
          >

          <label
            class="
              format-values-label format-pasarelas-values format-pep-label
              text-left
            "
            ><!-- <span class="font-size-pasarelas-icon">●</span> --> Suscripciones Móviles <br />
            {{ currency }} {{ pepValue.toFixed(2) }}</label
          >
        </div>
      </div>
    </div>
    
    <b-card-text v-if="loadedLabelNoData" class="font-weight-bolder texto-no-ventas">
      No se han encontrado transacciones
    </b-card-text>

    <!-- chart -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardSubTitle,
  BCardTitle,
  BFormDatepicker,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue';
import ChartjsComponentLineChart from './charts-components/ChartjsComponentLineChart.vue';
import chartjsData from './chartjsDataTransacciones';
import DropdownSplit from '../../../components/dropdown/DropdownSplit.vue';
import axios from 'axios';
import { backendUrl } from '@/config';

export default {
  name: 'ChartjsLineChartTransactions',
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
    BFormDatepicker,
    DropdownSplit,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  data() {
    return {
      loadedLabelNoData:false,
      loadedGraph: false,
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function() {
              this.height += 20;
            };
            /* eslint-enable */
          },
        },
      ],
      valueDesde: '',
      valueHasta: '',
      totalValue: 0,
      kushkiValue: 0,
      stripeValue: 0,
      pepValue: 0,
      totalTransacciones: [],
      fechas: [],
      chartColors: {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      },
      dataChart: null,
      enero:0,
      febrero:0,
      marzo:0,
      abril:0,
      mayo:0,
      junio:0,
      julio:0,
      agosto:0,
      septiembre: 0,
      octubre: 0,
      noviembre: 0,
      diciembre: 0,
      arrayStripe: [],
      arrayKushki: [],
      arrayPep: [],
      arrayFiltradoMes: [],
      reloadChart: false,
      currency: '',
      currencies:[],
       currentYear: [new Date().getFullYear(), new Date().getFullYear()-1, new Date().getFullYear()-2 ],
      yearSelected: new Date().getFullYear(),
      
    };
  },
  async created() {
    this.getCurrency();
    this.getTransacciones();


  },

   mounted() {
    /* let stripeSubsc = await kkkkk();
    let stripeSubsc = await kkkkk();
    let stripeSubsc = await kkkkk(); */
    
    this.currencies = this.$store.state.appConfig.currency;
    this.idProy = this.$store.state.appConfig.projecto.id;
    this.dataChart = {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      datasets: [
        {
          data: this.loadDataChartStripe() /* [21,37,100] */,
          label: 'Stripe',
          borderColor: '#ff4961',
          lineTension: 0.5,
          pointStyle: 'circle',
          backgroundColor: '#ff4961',
          fill: false,
          pointRadius: 1,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: '#ffffff',
          pointHoverBackgroundColor: '#ff4961',
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: 'rgba(0, 0, 0, 0.25)',
        },
        {
              data: this.loadDataChartKushki(),
              label: 'Kushki',
              borderColor: '#836AF9',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#836AF9',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#836AF9',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
            {
              data: this.loadDataChartPep(),
              label: 'Suscripciones Móviles',
              borderColor: '#ffc000',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ffc000',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#ffc000',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
      ],
    };
    this.getCurrency();
    this.$store.commit('appConfig/changeLoading', false);

  },

  computed: {
    idProy: function() {
      return this.$store.state.appConfig.projecto.id;
    },
     currencies: function(){
      return this.$store.state.appConfig.currency;
    }
  },
  methods: { 
      selectYear(year){
      this.yearSelected = year
      this.$store.commit('appConfig/changeLoading', true);

      this.getTransacciones()
    },
    async getCurrency() {
      this.currency = '';
      let projecto = this.$store.state.appConfig.projecto;
      let currenciesAux = this.$store.state.appConfig.currency;

      if(currenciesAux.length == 0){
         const token = localStorage.getItem("token");
        
        const responseM = await axios({
          method: "get",
          url: `${backendUrl}/operaciones/util`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        let monedas = responseM.data.currencies;
        this.$store.commit("appConfig/saveCurrency", monedas);
        currenciesAux = this.$store.state.appConfig.currency;
      }
      
      for (let index = 0; index < currenciesAux.length; index++) {
        const element = currenciesAux[index];
        if (projecto.currency == element.id) {
          this.currency = element.code;
          break;
        }
      }
    },
    async getTransacciones() {
      const token = localStorage.getItem('token');

      let { projecto, currency } = this.$store.state.appConfig;
      let projectoSelect = this.$store.state.appConfig.proyectosTotales.filter(
        (x) => x.id == projecto.id
      );

     
      const response = await axios({
        method: 'get',
        url: `${backendUrl}/operaciones/transacciones`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data.transactions) {
        return;
      } else {
        
        this.totalTransacciones  = response.data.transactions.filter((t) => 
          new Date(t.date).getFullYear() == this.yearSelected)
      


        this.arrayStripe = this.totalTransacciones.filter((sus) => {
          return sus.id_credential == '2';
        });

         this.arrayKushki = this.totalTransacciones.filter((sus) => {
          return sus.id_credential == '1';
        });

       const suscMobile = await axios({
          method: "get",
          url: `${backendUrl}/mobile/transactions`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });


        this.arrayPep = suscMobile.data;
        if (this.arrayStripe.length >= 0 || this.arrayKushki.length >= 0 || this.arrayPep.length >= 0) {
          //this.loading = false;
          this.$store.commit('appConfig/changeLoading', false);
        }

        /* for (let index = 0; index < this.totalTransacciones.length; index++) {

        this.totalValue += this.totalTransacciones[index].amount;
      }

      for (let i = 0; i < this.arrayStripe.length; i++) {
        this.stripeValue += this.arrayStripe[i].amount;
      } */
      }
      this.loadedGraph = true;
    },
    loadDataChartStripe() {
      this.totalValue = 0;
      this.stripeValue = 0;

      var result = [];

      for (let index = 0; index < this.totalTransacciones.length; index++) {
        if (this.totalTransacciones[index].id_project == this.idProy) {
          this.totalValue += this.totalTransacciones[index].amount;
        }
      }

      this.loadedLabelNoData = this.totalValue == 0 ? true :false;

      for (let i = 0; i < this.arrayStripe.length; i++) {
        if (this.arrayStripe[i].id_project == this.idProy) {
          this.stripeValue += this.arrayStripe[i].amount;
        }
      }

      this.arrayFiltradoMes = this.arrayStripe.filter((ar) => {
        return ar.id_project == this.idProy;
      });

      if (this.arrayFiltradoMes.length >= 0) {
        //this.loading = false;
        this.$store.commit('appConfig/changeLoading', false);
      }

      // this.agosto = (this.arrayFiltradoMes.filter((sa) =>{
      //   return new Date(sa.date).getMonth() === 7; //No es Julio, es Agosto
      // })).length;
        this.enero = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 0; //Enero
      }).length;
      
        this.febrero = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 1; //Febrero
      }).length;

        this.marzo = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 2; //Marzo
      }).length;

        this.abril = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 3; //Abril
      }).length;

        this.mayo = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 4; //Mayo
      }).length;

        this.junio = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 5; //Junio
      }).length;

        this.julio = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 6; //Julio
      }).length;

        this.agosto = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 7; //Agosto
      }).length;

        this.septiembre = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 8; //Septiembre
      }).length;

        this.octubre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 9; //Octubre
      }).length;

        this.noviembre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 10; //Noviembre
      }).length;

        this.diciembre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 11; //Diciembre
      }).length;

      result = [this.enero, this.febrero, this.marzo, this.abril, this.mayo, this.junio, this.julio, this.agosto, this.septiembre, this.octubre, this.noviembre, this.diciembre ];

      return result;
    },
    loadDataChartKushki() {
      this.totalValue = 0;
      this.kushkiValue = 0;

      var result = [];

      for (let index = 0; index < this.totalTransacciones.length; index++) {
        if (this.totalTransacciones[index].id_project == this.idProy) {
          this.totalValue += this.totalTransacciones[index].amount;
        }
      }

      this.loadedLabelNoData = this.totalValue == 0 ? true :false;

      for (let i = 0; i < this.arrayKushki.length; i++) {
        if (this.arrayKushki[i].id_project == this.idProy) {
          this.kushkiValue += this.arrayKushki[i].amount;
        }
      }

      this.arrayFiltradoMes = this.arrayKushki.filter((ar) => {
        return ar.id_project == this.idProy;
      });

      if (this.arrayFiltradoMes.length >= 0) {
        //this.loading = false;
        this.$store.commit('appConfig/changeLoading', false);
      }

      // this.agosto = (this.arrayFiltradoMes.filter((sa) =>{
      //   return new Date(sa.date).getMonth() === 7; //No es Julio, es Agosto
      // })).length;
       this.enero = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 0; //Enero
      }).length;
      
        this.febrero = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 1; //Febrero
      }).length;

        this.marzo = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 2; //Marzo
      }).length;

        this.abril = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 3; //Abril
      }).length;

        this.mayo = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 4; //Mayo
      }).length;

        this.junio = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 5; //Junio
      }).length;

        this.julio = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 6; //Julio
      }).length;

        this.agosto = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 7; //Agosto
      }).length;

      this.septiembre = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 8; //Septiembre
      }).length;

      this.octubre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 9; //Octubre
      }).length;

      this.noviembre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 10; //Noviembre
      }).length;

      this.diciembre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 11; //Diciembre
      }).length;

      result = [this.enero, this.febrero, this.marzo, this.abril, this.mayo, this.junio, this.julio, this.agosto, this.septiembre, this.octubre, this.noviembre, this.diciembre ];

      return result;
    },
     loadDataChartPep() {
      this.totalValue = 0;
      this.pepValue = 0;

      var result = [];

      for (let index = 0; index < this.totalTransacciones.length; index++) {
        if (this.totalTransacciones[index].id_project == this.idProy) {
          this.totalValue += this.totalTransacciones[index].amount;
        }
      }

      this.loadedLabelNoData = this.totalValue == 0 ? true :false;

      for (let i = 0; i < this.arrayPep.length; i++) {
        if (this.arrayPep[i].id_project == this.idProy) {
          this.pepValue += this.arrayPep[i].amount;
        }
      }

      this.arrayFiltradoMes = this.arrayPep.filter((ar) => {
        return ar.id_project == this.idProy;
      });

      if (this.arrayFiltradoMes.length >= 0) {
        //this.loading = false;
        this.$store.commit('appConfig/changeLoading', false);
      }

      // this.agosto = (this.arrayFiltradoMes.filter((sa) =>{
      //   return new Date(sa.date).getMonth() === 7; //No es Julio, es Agosto
      // })).length;
       this.enero = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 0; //Enero
      }).length;
      
        this.febrero = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 1; //Febrero
      }).length;

        this.marzo = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 2; //Marzo
      }).length;

        this.abril = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 3; //Abril
      }).length;

        this.mayo = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 4; //Mayo
      }).length;

        this.junio = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 5; //Junio
      }).length;

        this.julio = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 6; //Julio
      }).length;

        this.agosto = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 7; //Agosto
      }).length;

      this.septiembre = this.arrayFiltradoMes.filter((ss) => {
        return new Date(ss.date).getMonth() === 8; //Septiembre
      }).length;

      this.octubre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 9; //Octubre
      }).length;

      this.noviembre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 10; //Noviembre
      }).length;

      this.diciembre = this.arrayFiltradoMes.filter((so) => {
        return new Date(so.date).getMonth() === 11; //Diciembre
      }).length;

      result = [this.enero, this.febrero, this.marzo, this.abril, this.mayo, this.junio, this.julio, this.agosto, this.septiembre, this.octubre, this.noviembre, this.diciembre ];

      return result;
    },
  },
  watch: {
    arrayStripe: function(val) {
      if (val.length > 0) {
        this.dataChart = {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              data: this.loadDataChartStripe(),
              label: 'Stripe',
              borderColor: '#ff4961',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ff4961',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#ff4961',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
            {
              data: this.loadDataChartKushki(),
              label: 'Kushki',
              borderColor: '#836AF9',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#836AF9',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#836AF9',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
            {
              data: this.loadDataChartPep(),
              label: 'Suscripciones Móviles',
              borderColor: '#ffc000',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ffc000',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#ffc000',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
          ],
        };
      }
       this.reloadChart = true;
      setTimeout(() => {
        this.reloadChart = false;
      }, 100);
    },
     arrayKushki: function(val) {
      if (val.length > 0) {
        this.dataChart = {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              data: this.loadDataChartStripe(),
              label: 'Stripe',
              borderColor: '#ff4961',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ff4961',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#ff4961',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
            {
              data: this.loadDataChartKushki(),
              label: 'Kushki',
              borderColor: '#836AF9',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#836AF9',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#836AF9',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
            {
              data: this.loadDataChartPep(),
              label: 'Suscripciones Móviles',
              borderColor: '#ffc000',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ffc000',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#ffc000',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
          ],
        };
      }
       this.reloadChart = true;
      setTimeout(() => {
        this.reloadChart = false;
      }, 100);
    },
     arrayPep: function(val) {
      if (val.length >= 0) {
        this.dataChart = {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
          datasets: [
            {
              data: this.loadDataChartStripe(),
              label: 'Stripe',
              borderColor: '#ff4961',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ff4961',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#ff4961',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
            {
              data: this.loadDataChartKushki(),
              label: 'Kushki',
              borderColor: '#836AF9',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#836AF9',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#836AF9',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
            {
              data: this.loadDataChartPep(),
              label: 'Suscripciones Móviles',
              borderColor: '#ffc000',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ffc000',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#ffc000',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
          ],
        };
      }
       this.reloadChart = true;
      setTimeout(() => {
        this.reloadChart = false;
      }, 100);
    },
    idProy: function(val) {
      this.dataChart = {
          labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
          {
            data: this.loadDataChartStripe(),
            label: 'Stripe',
            borderColor: '#ff4961',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#ff4961',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: '#ffffff',
            pointHoverBackgroundColor: '#ff4961',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: 'rgba(0, 0, 0, 0.25)',
          },
          {
              data: this.loadDataChartKushki(),
              label: 'Kushki',
              borderColor: '#836AF9',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#836AF9',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#836AF9',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
            {
              data: this.loadDataChartPep(),
              label: 'Suscripciones Móviles',
              borderColor: '#ffc000',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ffc000',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#ffffff',
              pointHoverBackgroundColor: '#ffc000',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: 'rgba(0, 0, 0, 0.25)',
            },
        ],
      };
      this.getCurrency();
      this.reloadChart = true;
      setTimeout(() => {
        this.reloadChart = false;
      }, 100);
      this.$store.commit('appConfig/changeLoading', false);
      //this.loading = false;
    },
     currencies: function (val) {
      
      if(val.length >= 0){
      
      this.getCurrency();
      
      }
      this.reloadChart = true;
      setTimeout(() => {
        this.reloadChart = false;
      }, 100);
    },
  },
};
</script>

<style scoped>
.btn-info:hover {
  box-shadow: none !important;
}

.btn-filtro {
  min-width: 120px;
}

.form-control {
  font-weight: 600 !important;
}

.format-values-label {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 16px;
  margin-right: 10px;
  margin-left: 10px;
}
.contenedor {
  text-align: right;
}

.format-pasarelas-values {
  margin-left: 25px;
}

.format-pep-label {
  margin-right: 0px;
  color: #ffc000 !important;
}

.format-kushki-label {
  margin-right: 22px;
  color: #836af9 !important;
}

.format-stripe-label {
  margin-right: 18px;
  color: #ff4961 !important;
}

.texto-no-ventas {
  margin-left: 35%;
  font-size: 16px;
}

#loading-logo {
  width: 50%;
}
#loading-bg {
  width: 100%;
  height: 10%;
  background: transparent;
  display: block;
  position: absolute;
}
.loading-logo {
  position: absolute;
  left: calc(46% - 45px);
  top: 20%;
}
.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}
.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}
.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(0, 26, 87, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
